/* eslint-disable no-underscore-dangle */
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { GRAPHQL_URL } from '@src/libs/requests';
import { timeZoneHeader } from '@src/libs/requests/utils';
import { sentryLink } from '@src/libs/sentry';

declare const process: NodeJS.Process;
const __DEV__ = process.env.NODE_ENV !== 'production';

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');

  return {
    headers: {
      ...headers,
      timezone: timeZoneHeader,
      authorization: token || '',
    },
  };
});

// Error handling
const showError = onError(({ graphQLErrors, networkError }) => {
  if (__DEV__ && graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.captureException(new Error(message));
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        influencerProfileV2: {
          merge: true,
        },
        getChatEvents: {
          keyArgs: ['id'],
          merge(existing = {}, incoming = {}, { args }) {
            if (args?.input?.newerThan) {
              return {
                __typename: existing.__typename,
                userName: existing.userName,
                chatEvents: (existing?.chatEvents || []).concat(incoming?.chatEvents || []),
                lastCheckedAt: incoming?.lastCheckedAt,
              };
            }

            if (args?.input?.olderThan) {
              return {
                __typename: existing.__typename,
                userName: existing.userName,
                chatEvents: (incoming?.chatEvents || []).concat(existing?.chatEvents || []),
                lastCheckedAt: incoming?.lastCheckedAt,
              };
            }

            return incoming;
          },
        },
      },
    },
  },
});
const link = ApolloLink.from([authLink, sentryLink, showError, httpLink]);

const client = new ApolloClient({
  cache,
  link,
  connectToDevTools: __DEV__,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      // https://github.com/apollographql/@apollo/client/issues/6833
      // in short 'cache-and-network' may cause duplicate call to some APIs
      nextFetchPolicy: lastFetchPolicy => {
        if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only') {
          return 'cache-first';
        }

        return lastFetchPolicy;
      },
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'none',
    },
  },
});

export default client;
