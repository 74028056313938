import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import emptyYoutubeCmsRevenue from '@src/assets/img/youtubeCMSDashboard/emptyYoutubeCmsRevenue.png';
import { Avatar, Icomoon, Image, TextCutter } from '@src/components/atoms';
import { ListLoading, Pager } from '@src/components/molecules';
import { getPageInfo } from '@src/components/molecules/Pager/helpers';
import { YearMonthPicker } from '@src/components/shared';
import Badge from '@src/components/organisms/Notification/Badge';
import {
  useYoutubeCmsChannelsForInfluencerQuery,
  useYoutubeCmsConfirmedChannelsForInfluencerQuery,
  useYoutubeCmsConfirmedMusicsForInfluencerQuery,
  useYoutubeCmsConfirmedRevenueForInfluencerQuery,
  useYoutubeCmsConfirmedVideosForInfluencerQuery,
  useYoutubeCmsMusicsForInfluencerQuery,
  useYoutubeCmsRevenueForInfluencerQuery,
  useYoutubeCmsStartMonthForInfluencerQuery,
  useYoutubeCmsVideosForInfluencerQuery,
} from '@src/graphql/hooks';
import { CURRENCY_SYMBOLS, DEFAULT_FNS_DATE_FORMAT, LIMIT_10 } from '@src/libs/constant';
import { formatNumberWithCommas } from '@src/libs/format';
import { useHelpCenterUrl } from '@src/libs/help';
import { useFilter, usePageLayout, useUrl } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { lineWidgetState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';

enum Types {
  CHANNEL = 'CHANNEL',
  MUSIC = 'MUSIC',
  VIDEO = 'VIDEO',
}

const YoutubeCMSRevenue = () => {
  const [filterBy, setFilterBy] = useState<Types>(Types.CHANNEL);
  const { searchParams } = useUrl();
  const page = Number(searchParams.get('p')) || 1;
  const { filter, setFilter } = useFilter({
    month: searchParams.get('month') || '',
    p: page,
  });
  const { learnAboutEstimatedRevenue } = useHelpCenterUrl();
  const { isMobileView } = usePageLayout();
  const { t } = useTranslation();
  const { setRecoilState } = useRecoil(lineWidgetState);
  const imageSize = isMobileView ? 48 : 40;
  const scrollLimit = 5;

  const { data: dataStartMonth, loading: loadingStartMonth } = useYoutubeCmsStartMonthForInfluencerQuery();
  const marginRateStartMonth = dataStartMonth?.youtubeCmsStartMonthForInfluencer?.month;

  const variables = {
    ...(filter.month && { month: filter.month }),
  };
  const { data: dataRevenue, loading: loadingRevenue } = useYoutubeCmsRevenueForInfluencerQuery({
    skip: loadingStartMonth,
    variables,
  });
  const { data: dataConfirmedRevenue, loading: loadingConfirmedRevenue } =
    useYoutubeCmsConfirmedRevenueForInfluencerQuery({
      skip: loadingStartMonth,
      variables,
    });
  const isConfirmedRevenue = !!dataConfirmedRevenue?.youtubeCmsConfirmedRevenueForInfluencer?.revenue?.total;
  const isLoadingRevenue = loadingRevenue || loadingConfirmedRevenue;
  const youtubeCmsRevenue = isConfirmedRevenue
    ? dataConfirmedRevenue?.youtubeCmsConfirmedRevenueForInfluencer?.revenue
    : dataRevenue?.youtubeCmsRevenueForInfluencer?.revenue;
  const currencySymbol = youtubeCmsRevenue?.currency ? CURRENCY_SYMBOLS[youtubeCmsRevenue.currency] : '';
  const tabs = {
    [Types.CHANNEL]: {
      columnTitle: 'Channel name',
      title: 'Channel',
    },
    [Types.MUSIC]: {
      columnTitle: 'Rewarded Music',
      title: 'Music',
    },
    [Types.VIDEO]: {
      columnTitle: 'Video with Music',
      title: 'Video',
    },
  };
  const selectedTab = tabs[filterBy];

  const skipQuery = isLoadingRevenue || isConfirmedRevenue || !youtubeCmsRevenue?.total;
  const { data: dataChannels, loading: loadingChannels } = useYoutubeCmsChannelsForInfluencerQuery({
    skip: skipQuery || filterBy !== Types.CHANNEL,
    variables,
  });
  const { data: dataMusics, loading: loadingMusics } = useYoutubeCmsMusicsForInfluencerQuery({
    skip: skipQuery || filterBy !== Types.MUSIC,
    variables,
  });
  const { data: dataVideos, loading: loadingVideos } = useYoutubeCmsVideosForInfluencerQuery({
    skip: skipQuery || filterBy !== Types.VIDEO,
    variables,
  });

  const skipConfirmedQuery = isLoadingRevenue || !isConfirmedRevenue || !youtubeCmsRevenue?.total || !filter.month;
  const { data: dataConfirmedChannels, loading: loadingConfirmedChannels } =
    useYoutubeCmsConfirmedChannelsForInfluencerQuery({
      skip: skipConfirmedQuery || filterBy !== Types.CHANNEL,
      variables: {
        month: filter.month,
      },
    });
  const { data: dataConfirmedMusics, loading: loadingConfirmedMusisc } = useYoutubeCmsConfirmedMusicsForInfluencerQuery(
    {
      skip: skipConfirmedQuery || filterBy !== Types.MUSIC,
      variables: {
        month: filter.month,
      },
    }
  );
  const { data: dataConfirmedVideos, loading: loadingConfirmedVideos } = useYoutubeCmsConfirmedVideosForInfluencerQuery(
    {
      skip: skipConfirmedQuery || filterBy !== Types.VIDEO,
      variables: {
        month: filter.month,
      },
    }
  );

  useEffect(() => {
    setRecoilState({ isHidden: true });

    return () => {
      setRecoilState({ isHidden: false });
    };
  }, []);

  useEffect(() => {
    if (marginRateStartMonth) {
      const date = new Date();
      const marginRateDate = new Date(marginRateStartMonth);
      const selectedDate = filter.month ? new Date(filter.month) : null;

      const firstDayOfTheMonth = selectedDate
        ? selectedDate.setHours(0, 0, 0, 0) >= marginRateDate.setHours(0, 0, 0, 0)
          ? filter.month
          : marginRateStartMonth
        : date.setHours(0, 0, 0, 0) >= marginRateDate.setHours(0, 0, 0, 0)
        ? format(new Date(date.getFullYear(), date.getMonth(), 1), DEFAULT_FNS_DATE_FORMAT)
        : marginRateStartMonth;
      setFilter({ ...filter, month: firstDayOfTheMonth, p: page });
    }
  }, [filter.month, marginRateStartMonth, page]);

  const isLoadingList =
    loadingChannels ||
    loadingMusics ||
    loadingVideos ||
    loadingConfirmedChannels ||
    loadingConfirmedMusisc ||
    loadingConfirmedVideos;
  const listItems =
    (filterBy === Types.CHANNEL
      ? isConfirmedRevenue
        ? dataConfirmedChannels?.youtubeCmsConfirmedChannelsForInfluencer
        : dataChannels?.youtubeCmsChannelsForInfluencer
      : filterBy === Types.MUSIC
      ? isConfirmedRevenue
        ? dataConfirmedMusics?.youtubeCmsConfirmedMusicsForInfluencer.map(item => ({
            ...item,
            thumbnail: null,
          }))
        : dataMusics?.youtubeCmsMusicsForInfluencer.map(item => ({
            ...item,
            thumbnail: null,
          }))
      : isConfirmedRevenue
      ? dataConfirmedVideos?.youtubeCmsConfirmedVideosForInfluencer
      : dataVideos?.youtubeCmsVideosForInfluencer.map(({ currency, id, revenue, shareRate, thumbnail, title }) => ({
          currency,
          id,
          name: title,
          revenue,
          shareRate,
          thumbnail,
        }))) || [];
  const pageInfo = getPageInfo(page || 1, listItems.length, LIMIT_10);

  const onChangeFilterBy = (type: Types) => {
    setFilterBy(type);
    setFilter({ ...filter, p: 1 });
  };

  const onChangePicker = (month: string) => {
    setFilter({ ...filter, month, p: 1 });
  };

  if (!loadingStartMonth && !isLoadingRevenue && !youtubeCmsRevenue) {
    return <Navigate to={ROUTES.FIND_JOBS} />;
  }

  const yearMonthPicker = (
    <YearMonthPicker
      css={{ width: '60%' }}
      value={filter.month || ''}
      onChange={onChangePicker}
      {...(marginRateStartMonth && { disabledDates: { before: new Date(marginRateStartMonth) } })}
    />
  );

  return (
    <div>
      {isMobileView ? (
        <div css={styles.mobilePickerContainer}>{yearMonthPicker}</div>
      ) : (
        <div css={styles.header}>
          <div className="selector-container">{yearMonthPicker}</div>
          <div className="badge-container">
            <Badge />
          </div>
        </div>
      )}

      {isLoadingRevenue ? (
        <ListLoading isTransparentBg />
      ) : (
        <div css={!isMobileView && { display: 'grid', justifyItems: 'center', padding: 16 }}>
          <div css={styles.revenueContainer}>
            <Status isConfirmedRevenue={isConfirmedRevenue}>{t(isConfirmedRevenue ? 'Confirmed' : 'Estimated')}</Status>
            <label className="revenue">{`${currencySymbol}${formatNumberWithCommas(
              youtubeCmsRevenue?.total,
              undefined,
              youtubeCmsRevenue?.currency
            )}`}</label>
            <div className="link">
              <Icomoon color={THEME.font.colors.blue.main} icon="warning-circle-outlined" size={16} />
              <Link target="_blank" to={learnAboutEstimatedRevenue}>
                {t(
                  isConfirmedRevenue
                    ? 'Annotation.Amount shown excludes taxes and other deductions'
                    : 'Annotation.Learn more about Estimated Revenue'
                )}
              </Link>
            </div>
          </div>

          {!youtubeCmsRevenue?.total ? (
            <div css={styles.emptyListContainer}>
              <img height={160} src={emptyYoutubeCmsRevenue} width={160} />
              <label>{t('Annotation.No revenue generated yet')}</label>
            </div>
          ) : (
            <div css={styles.contentContainer}>
              <div css={styles.tabs}>
                {Object.keys(tabs).map(key => (
                  <Tab isActive={key === filterBy} key={key} onClick={() => onChangeFilterBy(key as Types)}>
                    {t(tabs[key as Types.MUSIC].title)}
                  </Tab>
                ))}
              </div>
              <TableHeader isConfirmedRevenue={isConfirmedRevenue}>
                <div>{t(selectedTab?.columnTitle || '')}</div>
                <div>{t(isConfirmedRevenue ? 'Confirmed Revenue' : 'Estimated Revenue')}</div>
              </TableHeader>
              {isLoadingList ? (
                <ListLoading
                  css={
                    isMobileView
                      ? {
                          position: 'fixed',
                          top: 303,
                          width: 'fill-available',
                        }
                      : { background: THEME.colors.white, border: 'none' }
                  }
                  height="250px"
                  spinnerProps={{
                    color: THEME.colors.disabled,
                    size: '32px',
                    thickness: '4px',
                  }}
                />
              ) : (
                <div
                  css={[
                    styles.listContainer,
                    isMobileView && listItems.length >= scrollLimit && { height: 'calc(100vh - 303px)' },
                  ]}
                >
                  {listItems
                    .slice(isMobileView ? 0 : (page - 1) * LIMIT_10, isMobileView ? listItems.length : LIMIT_10 * page)
                    .map(({ currency, id, name, revenue, thumbnail }, index) => {
                      const isOtherAsset = id === 'other';

                      return (
                        <ListItem
                          isConfirmedRevenue={isConfirmedRevenue}
                          isOtherAsset={isOtherAsset}
                          key={`${index}_${id}`}
                        >
                          <div>
                            {isOtherAsset ? (
                              <div>
                                <Icomoon
                                  icon={filterBy === Types.CHANNEL ? 'avatars' : 'videos'}
                                  size={isMobileView ? 40 : 32}
                                />
                              </div>
                            ) : filterBy === Types.CHANNEL ? (
                              name || thumbnail ? (
                                <Avatar size={isMobileView ? 48 : 40} src={thumbnail || ''} title={name || ''} />
                              ) : (
                                <div css={styles.emptyAvatarContainer}>
                                  <Icomoon icon="image-grey" size={isMobileView ? 16 : 14} />
                                </div>
                              )
                            ) : filterBy === Types.VIDEO ? (
                              thumbnail ? (
                                <Image
                                  css={{ borderRadius: THEME.box.borderRadius.m, minWidth: imageSize }}
                                  height={imageSize}
                                  src={thumbnail}
                                  width={imageSize}
                                />
                              ) : (
                                <div css={styles.emptyImageContainer}>
                                  <Icomoon
                                    color={THEME.colors.gray.c5d0da}
                                    icon="dropdown"
                                    size={isMobileView ? 24 : 20}
                                  />
                                </div>
                              )
                            ) : null}
                            <TextCutter
                              lines={2}
                              text={
                                isOtherAsset
                                  ? filterBy === Types.CHANNEL
                                    ? t('Annotation.Total Revenue from Other Channels')
                                    : t('Annotation.Total Revenue from Other Videos')
                                  : name || id
                              }
                            />
                          </div>
                          <div>{`${currencySymbol}${formatNumberWithCommas(revenue, undefined, currency)}`}</div>
                        </ListItem>
                      );
                    })}

                  {isMobileView && listItems.length >= scrollLimit && (
                    <div css={{ background: THEME.colors.white, height: 180 }} />
                  )}
                </div>
              )}
            </div>
          )}

          {!isMobileView && (
            <Pager
              css={styles.pager}
              currentPage={page || 1}
              first={pageInfo.firstIndex}
              last={pageInfo.lastIndex}
              totalCount={pageInfo.totalCount}
              totalPages={pageInfo.totalPages}
            />
          )}
        </div>
      )}
    </div>
  );
};

const ListItem = styled.div<{ isConfirmedRevenue: boolean; isOtherAsset: boolean }>(
  ({ isConfirmedRevenue, isOtherAsset }) => ({
    alignItems: 'center',
    background: THEME.colors.white,
    color: isOtherAsset ? THEME.font.colors.gray.main : THEME.font.colors.black.main,
    display: 'flex',
    fontSize: isOtherAsset ? THEME.font.sizes.subordinate : THEME.font.sizes.normal,
    fontWeight: isOtherAsset ? 600 : 400,
    padding: '12px 24px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      ...(isOtherAsset && { fontSize: 13 }),
    },

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      gap: THEME.box.gaps.s,
      minWidth: '60%',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        minWidth: '70%',
      },
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      textAlign: 'right',
      width: 'fill-available',
      ...(isConfirmedRevenue && { color: THEME.font.colors.success }),
    },
  })
);

const Status = styled.label<{ isConfirmedRevenue: boolean }>(({ isConfirmedRevenue }) => ({
  background: isConfirmedRevenue ? '#c6ead8' : THEME.colors.gray.dee5ec,
  borderRadius: THEME.box.borderRadius.m,
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subordinate,
  padding: '2px 8px',
  width: 'fit-content',
}));

const Tab = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  color: isActive ? THEME.font.colors.black.main : '#a8b4bf',
  cursor: 'pointer',
  fontSize: THEME.font.sizes.subHeading,
  fontWeight: 600,
  padding: '15px 0 12px',
  textAlign: 'center',
  ...(isActive && { borderBottom: '2px solid #27313b' }),

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    padding: '22px 0 12px',
  },
}));

const TableHeader = styled.div<{ isConfirmedRevenue: boolean }>(({ isConfirmedRevenue }) => ({
  background: THEME.colors.white,
  borderBottom: THEME.box.borders.gray.main,
  color: THEME.font.colors.gray.main,
  display: 'flex',
  fontSize: THEME.font.sizes.subordinate,
  padding: '12px 24px',

  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    position: 'fixed',
    top: 264,
    width: 'fill-available',
  },

  '& > div:nth-of-type(1)': {
    minWidth: '60%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      minWidth: '70%',
    },
  },

  '& > div:nth-of-type(2)': {
    textAlign: 'right',
    width: 'fill-available',
    ...(isConfirmedRevenue && { color: THEME.font.colors.success }),
  },
}));

const styles = {
  contentContainer: css({
    border: THEME.box.borders.gray.main,
    maxWidth: 614,
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: THEME.box.borderRadius.l,
      overflow: 'hidden',
    },
  }),
  emptyAvatarContainer: css({
    alignItems: 'center',
    background: THEME.colors.gray.dee5ec,
    borderRadius: '50%',
    display: 'grid',
    justifyContent: 'center',
    minHeight: 48,
    minWidth: 48,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      minHeight: 40,
      minWidth: 40,
    },
  }),
  emptyImageContainer: css({
    alignItems: 'center',
    background: THEME.colors.gray.dee5ec,
    borderRadius: THEME.box.borderRadius.m,
    display: 'grid',
    justifyContent: 'center',
    minHeight: 48,
    minWidth: 48,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      minHeight: 40,
      minWidth: 40,
    },

    '& svg': {
      transform: 'rotate(270deg)',
    },
  }),
  emptyListContainer: css({
    alignContent: 'center',
    color: THEME.font.colors.gray.main,
    display: 'grid',
    fontSize: THEME.font.sizes.subHeading,
    gap: THEME.box.gaps.l,
    justifyItems: 'center',
    position: 'fixed',
    textAlign: 'center',
    top: 312,
    width: '100%',
    whiteSpace: 'pre-line',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: THEME.box.borderRadius.l,
      border: THEME.box.borders.gray.main,
      height: 327,
      position: 'unset',
      top: 'unset',
      width: 614,
    },
  }),
  header: css({
    alignItems: 'center',
    background: THEME.colors.white,
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    position: 'relative',
    width: 'fill-available',

    '& > .selector-container': {
      width: 536,
    },

    '& > .badge-container': {
      right: 40,
      margin: 'auto 0',
      position: 'absolute',
    },
  }),
  listContainer: css({
    background: THEME.colors.gray.dee5ec,
    display: 'grid',
    gap: 1,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      overflow: 'auto',
      position: 'fixed',
      top: 303,
      width: 'fill-available',
    },
  }),
  mobilePickerContainer: css({
    background: THEME.colors.white,
    padding: '8px 16px',
    position: 'fixed',
    width: 'fill-available',
  }),
  pager: css({
    marginBottom: 80,

    '& > div:nth-of-type(1)': {
      display: 'none',
    },

    '& p': {
      borderRadius: THEME.box.borderRadius.xs,
      width: 88,
    },

    '& li': {
      borderRadius: THEME.box.borderRadius.xs,
    },
  }),
  revenueContainer: css({
    alignItems: 'center',
    background: THEME.colors.white,
    display: 'grid',
    gap: THEME.box.gaps.s,
    maxWidth: 614,
    padding: '8px 16px 18px 16px',
    position: 'fixed',
    width: 'fill-available',
    top: 96,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: THEME.box.borderRadius.l,
      boxShadow: THEME.box.shadows.outer,
      justifyItems: 'center',
      marginBottom: 12,
      padding: '22px 0',
      position: 'unset',
    },

    '& > .revenue': {
      color: THEME.font.colors.black.main,
      fontSize: 32,
      fontWeight: 700,
    },

    '& > .link': {
      alignItems: 'center',
      display: 'flex',
      gap: THEME.box.gaps.xs,

      '& > a': {
        color: THEME.font.colors.blue.main,
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
  tabs: css({
    background: '#f6f8fa',
    borderBottom: THEME.box.borders.gray.main,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: '0 16px',
    position: 'fixed',
    top: 214,
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '0 24px',
      position: 'unset',
    },
  }),
};

export default YoutubeCMSRevenue;
