import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { useGetInfluencerChatServicesQuery, useInfluencerProfileV2Query } from '@src/graphql/hooks';
import { useAuthData, useGlobalLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { lineWidgetState, useRecoil } from '@src/recoilAtoms';
import { UserRoles } from '@src/__generated__/globalTypes';

declare const IS_DISABLED_ON_PROD: boolean;

const LineWidget = () => {
  const { role, userId } = useAuthData();
  const { hasAnalyticsTabs, hasIntercomWidget, hasMobileMenu, setGlobalLayout } = useGlobalLayout();
  const {
    recoilState: { extendedBottom, isHidden },
  } = useRecoil(lineWidgetState);
  const { data } = useGetInfluencerChatServicesQuery({
    skip: IS_DISABLED_ON_PROD || isHidden || role === UserRoles.CREATOR_STAFF,
  });
  const { data: dataInfluencerProfile } = useInfluencerProfileV2Query({
    skip: !IS_DISABLED_ON_PROD,
    variables: {
      pk: Number(userId),
    },
  });
  const liffUrl =
    IS_DISABLED_ON_PROD && dataInfluencerProfile?.influencerProfileV2?.country.id === 'JP'
      ? 'https://lin.ee/iyGRTe1'
      : data?.getInfluencerChatServices?.line?.liffUrl;
  const isLineServiceAvailable = !!liffUrl;

  useEffect(() => {
    // menu component will run setGlobalLayout, set 1 second to avoid interruption
    setTimeout(() => {
      setGlobalLayout({ hasLineWidget: isLineServiceAvailable });
    }, 1000);
  }, [isLineServiceAvailable]);

  return isLineServiceAvailable ? (
    <Container
      className="contact-line-widget"
      extendedBottom={extendedBottom || 0}
      hasAnalyticsTabs={hasAnalyticsTabs}
      hasIntercomWidget={hasIntercomWidget}
      hasMobileMenu={hasMobileMenu}
      target="_blank"
      to={liffUrl}
    >
      <div>
        <Icomoon icon="line-chat-white" size={22} />
        <div>
          <Trans components={{ 1: <b /> }} i18nKey="CONTACT from LINE" />
        </div>
      </div>
    </Container>
  ) : null;
};

const Container = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  extendedBottom: number;
  hasAnalyticsTabs: boolean;
  hasIntercomWidget: boolean;
  hasMobileMenu: boolean;
}>(({ extendedBottom, hasAnalyticsTabs, hasIntercomWidget, hasMobileMenu }) => ({
  '--analyticsTabsHeight': hasAnalyticsTabs ? '57px' : '0px',
  '--intercomWidgetHeight': hasIntercomWidget ? '48px' : '0px',
  '--menuHeight': hasMobileMenu ? '64px' : '0px',
  '--gap': extendedBottom || hasAnalyticsTabs || hasIntercomWidget || hasMobileMenu ? '8px' : '0px',

  alignItems: 'center',
  background: THEME.colors.black.main,
  borderRadius: 16,
  bottom: `calc(${extendedBottom}px + var(--analyticsTabsHeight) + var(--intercomWidgetHeight) + var(--menuHeight) + var(--gap))`,
  boxShadow: THEME.box.shadows.outer,
  display: 'grid',
  height: 56,
  justifyContent: 'center',
  padding: 8,
  position: 'fixed',
  right: 12,
  width: 56,
  zIndex: 5,

  '& > div': {
    alignItems: 'center',
    display: 'grid',
    gap: THEME.box.gaps.xs,

    '& > svg': {
      justifySelf: 'center',
    },

    '& > div': {
      color: THEME.font.colors.white,
      fontSize: 8,
      fontWeight: 500,
      textAlign: 'center',

      '& > b': {
        fontSize: THEME.font.sizes.hint,
        fontWeight: 700,
      },
    },
  },
}));

export default LineWidget;
