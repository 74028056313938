/* eslint-disable no-underscore-dangle */
import { APP_LANGUAGES } from '@src/libs/i18n/languageDetector/utils';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

declare const IS_DISABLED_ON_PROD: boolean;

const CrowdinButton = () => {
  const { i18n } = useTranslation();

  const injectCrowdin = useCallback(() => {
    if (window._jipt) {
      return;
    } else {
      window._jipt = [['project', 'anytag']];

      const crowdinWidget = document.createElement('script');
      crowdinWidget.src = '//cdn.crowdin.com/jipt/jipt.js';
      crowdinWidget.type = 'text/javascript';
      crowdinWidget.async = true;

      document.getElementsByTagName('body')[0].appendChild(crowdinWidget);
      i18n.changeLanguage(APP_LANGUAGES.pseudo);
    }
  }, [i18n.language]);

  return !IS_DISABLED_ON_PROD ? (
    <button
      onClick={injectCrowdin}
      css={{
        cursor: 'pointer',
        margin: '0 18px',
        '&:hover': {
          opacity: '0.8',
        },
      }}
    >
      Translate
    </button>
  ) : null;
};

export default CrowdinButton;
