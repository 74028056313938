import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { AudioPlayer, CircularProgress, Icomoon, Image } from '@src/components/atoms';
import { useTrackYoutubeMusicDownloadMutation } from '@src/graphql/hooks';
import { formatDuration } from '@src/libs/format';
import { useQueryHelper } from '@src/libs/hooks';
import { downloadFile } from '@src/libs/requests/downloadFile';
import { THEME } from '@src/libs/theme';
import { YoutubeMusicItem } from '@src/__generated__/globalTypes';

interface MusicPlayerProps extends YoutubeMusicItem {
  isSelected: boolean;
  onClickDownload: () => void;
  onClickSelectPlayback: () => void;
}

const MusicPlayer = ({
  downloadUrl,
  duration,
  id,
  isSelected,
  thumbnailUrl,
  title,
  onClickDownload,
  onClickSelectPlayback,
}: MusicPlayerProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef({
    audio: { current: { currentTime: 0, pause: () => null, play: () => null } },
  });
  const { enqueueSnackbar, t } = useQueryHelper();
  const [trackYoutubeMusicDownload] = useTrackYoutubeMusicDownloadMutation();

  useEffect(() => {
    if (isDownloading) {
      stopMusic();
      onClickDownload();
    }
  }, [isDownloading]);

  useEffect(() => {
    if (!isSelected && isPlaying) {
      stopMusic();
    }
  }, [isSelected]);

  const onClickDownloadMusic = async () => {
    setIsDownloading(true);
    try {
      const { error } = await downloadFile({
        filenamePrefix: 'music',
        hasAuthHeader: false,
        url: new URL(downloadUrl),
      });
      if (error) {
        enqueueSnackbar(t('The music download failed'), { variant: 'error' });
      } else {
        await trackYoutubeMusicDownload({ variables: { input: { musicId: id } } });
        enqueueSnackbar(t('The download was successful'), { variant: 'success' });
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
    setIsDownloading(false);
  };

  const onClickMusic = () => {
    if (!downloadUrl) {
      return;
    }

    onClickSelectPlayback();
    if (isPlaying) {
      audioRef.current?.audio?.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.audio?.current?.play();
      setIsPlaying(true);
    }
  };

  const stopMusic = () => {
    audioRef.current?.audio?.current?.pause();
    if (audioRef.current?.audio?.current) {
      audioRef.current.audio.current.currentTime = 0;
    }
    setIsPlaying(false);
  };

  return (
    <div css={styles.container}>
      <div css={styles.content}>
        <div css={isDownloading && { pointerEvents: 'none' }} onClick={onClickMusic}>
          <Thumbnail isSelected={isSelected}>
            <div className="overlay">
              <Icomoon
                color={THEME.colors.white}
                icon={isPlaying ? 'pause-circle-outlined' : 'play-circle-outlined'}
                size={32}
              />
            </div>
            <Image height="54px" hideNoImageText src={thumbnailUrl} width="54px" />
          </Thumbnail>
          <div className="title-container">
            <label className="title">{title}</label>
            <label className="duration">{formatDuration(duration)}</label>
          </div>
        </div>
        {isDownloading ? (
          <CircularProgress color={THEME.colors.gray.main} size="24px" thickness="2px" />
        ) : (
          <Icomoon css={{ cursor: 'pointer' }} icon="import-straight-line" size={18} onClick={onClickDownloadMusic} />
        )}
      </div>

      <AudioPlayer
        css={[styles.audioPlayer, !isSelected && { display: 'none' }]}
        preload="metadata"
        ref={audioRef}
        src={downloadUrl}
        onEnded={stopMusic}
      />
    </div>
  );
};

const Thumbnail = styled.div<{ isSelected: boolean }>(({ isSelected }) => ({
  alignItems: 'center',
  border: isSelected ? '2px solid #ff4470' : '2px solid #fff',
  borderRadius: THEME.box.borderRadius.m,
  display: 'grid',
  justifyContent: 'center',
  minHeight: 54,
  minWidth: 54,
  overflow: 'hidden',
  position: 'relative',

  '& > .overlay': {
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
    display: 'grid',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    visibility: isSelected ? 'visible' : 'hidden',
    width: '100%',
  },
}));

const styles = {
  audioPlayer: css({
    backgroundColor: 'unset',
    boxShadow: 'unset',
    padding: 'unset',

    '& .rhap_current-time, .rhap_time': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.hint,
    },

    '& .rhap_progress-bar': {
      background: THEME.colors.gray.dee5ec,
    },

    '& .rhap_download-progress': {
      background: THEME.colors.gray.c5d0da,
    },

    '& .rhap_progress-filled': {
      background: THEME.colors.red.ff4470,
    },

    '& .rhap_progress-indicator': {
      background: THEME.colors.white,
      height: 16,
      top: -6,
      width: 16,
    },

    '& .rhap_controls-section': {
      display: 'none',
    },
  }),
  container: css({
    background: THEME.colors.white,
    display: 'grid',
    gap: THEME.box.gaps.xs,
    padding: '8px 16px',
  }),
  content: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.m,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      gap: THEME.box.gaps.m,
      width: 'fill-available',

      '&:hover .overlay': {
        visibility: 'visible',
      },

      '& > .title-container': {
        display: 'grid',
        gap: THEME.box.gaps.xs,
        width: 'fill-available',

        '& > .title': {
          color: THEME.font.colors.black.main,
          cursor: 'pointer',
          fontSize: THEME.font.sizes.normal,
        },

        '& > .duration': {
          color: THEME.font.colors.gray.main,
          cursor: 'pointer',
          fontSize: THEME.font.sizes.hint,
        },
      },
    },
  }),
};

export default MusicPlayer;
